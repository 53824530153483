.loginPage {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  /* position: relative; */
  flex-wrap: wrap;
  background-image: linear-gradient(to right, #392546 , #574763);
}

.loginPage_button {
  width: 180px;
  height: 30px;
  background-color: blueviolet;
  border-radius: 20px;
  text-align: center;
  padding-top: 7px;
}

.loginPage_input {
  width: 100%;
  font-size: x-large;
}