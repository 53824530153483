.underslider_container {
    width: calc(100vw - 20px);
    height: 25vh;
    background-color: #9369a1;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: rgb(0 0 0 / 27%) 0px 0px 10px 6px;
}

.underslider_textContainer {
    width: inherit;
    /* padding: 30px 0; */
    text-align: center;    
}

.underslider_textContainer div {
    margin: 25px 0;
    font-size: 30px;
}