.prova{
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.sideSliderCloseButton {
    width: 40px;
    height: 40px;
    margin: 10px 15px;
    /* background-image: url("https://i.imgur.com/AMtO0A8.png");         */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

  /*LARGE*/
  @media screen and (min-width: 993px) {
    .sideSlider_container {
        z-index: 15; 
        height: 100vh;
        /* right: props.show === props.order ? "0" : "-30vw",  */
        /*transition: transitionTime+"s",*/ 
        /*padding:"15px",*/ 
    }

    .sideSlider_show {
        width: 320px;
    }

    .sideSlider_hide {
        width: 0vw;
    }    

    .sideSlider_show_mobile {}

    .sideSlider_hide_mobile {}

    .sideSlider_filter_hide {
        position: absolute;
        left: -300px;
        width: 300px;
        transition: 1s;
    }
    .sideSlider_filter_hide_MARKERS_TAB {
        position: absolute;
        left: -70px;
        width: 300px;
        transition: 1s;
    }

    .sideSlider_filter_show {
        position: absolute;
        left: 0;
        width: 300px;
        transition: 1s;
    }
    .sideSlider_filter_show_MARKERS_TAB {
        position: absolute;
        left: 230px;
        width: 300px;
        transition: 1s;
    }

    .sideSlider_filter_hide_mobile {}

    .sideSlider_filter_show_mobile {}

    .sideSliderSidelabel {
        left: 300px;
        bottom: 200px;
        width: 60px;
        height: 60px;
        position: absolute;
        border-top-right-radius: 25%;
        border-bottom-right-radius: 25%;
        /* background-image: url("./");         */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }

    .sideSlider_childrenContainer {
        height: calc(100vh - 90px);
        overflow-y: auto;
        padding: 15px;
    }
  }

  /*SMALL*/
  @media screen and (max-width: 992px)  {
    .sideSlider_container {
        z-index: 500; 
        height: 90vh;        
        max-height: calc(100vh - 75px);
        width: 100vw;
        position: absolute;
        transition: 1s;
        /* right: props.show === props.order ? "0" : "-30vw",  */
        /*transition: transitionTime+"s",*/ 
        /*padding:"15px",*/ 
    }

    .sideSlider_show_mobile {
        bottom: 0;
    }

    .sideSlider_hide_mobile {
        bottom: -90vh;
    }

    .sideSlider_show {}

    .sideSlider_hide {} 

    .sideSlider_filter_hide {}

    .sideSlider_filter_show {}

    .sideSlider_filter_hide_mobile {
        position: absolute;
        left: 0;
        bottom: -90vh;
        width: 100vw;
        transition: 1s;
    }

    .sideSlider_filter_show_mobile {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        transition: 1s;
    }

    .sideSliderSidelabel {
        left: 60px;
        top: -60px;
        width: 60px;
        height: 60px;
        position: absolute;
        border-top-left-radius: 25%;
        border-top-right-radius: 25%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
    }

    .sideSlider_childrenContainer {
        height: calc(90vh - 110px);
        overflow-y: auto;
        padding: 15px;
        width: calc(100vw - 30px);
    }

  }