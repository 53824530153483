.modalContainer_background {
    z-index: 12000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.modalContainer_container {
    max-width: 90vw;
    height: 90vh;
    background: red;
    
}