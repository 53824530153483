.generic_button_style{
    width: 220px;
    margin: 5px 0 5px 5px;
    /* margin: 5px; */
    border: 2px solid transparent;
    background: linear-gradient(to bottom, #FBB040, #f9db32);
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.generic_button_modal{
    width: 160px;
}

.generic_button_style:hover{
    background: linear-gradient(to bottom, #dd9831, #f0d124);
    transition: 0.5s;
    box-shadow: 0px 7px 10px #444444;
}

.short_styling{
    width: 200px;
}

.red_styling{
    background: linear-gradient(to top, #f70202, #ad0000);
    color: white;
}
.red_styling:hover{
    background: linear-gradient(to top, #c90101, #960101);
}

.green_styling{
    background: linear-gradient(to top, #2bfc01, #4daa00);
    color: black;
}
.green_styling:hover{
    background: linear-gradient(to top, #1ba300, #347400);
}

@media screen and (max-width: 992px) {
    .generic_button_style{
        margin: 5px 0;
        width: calc(100vw - 30px);
    }

    .short_styling{
        width: calc(100vw - 72px);
    }

    .generic_button_modal{
        width: 120px;
        font-size: 18px;
    }
}