.autocompleteContainer {
    z-index: 1;
    /* width: 90vw;
    height: 50px;
    background-color: yellow; */
    position: absolute;
    top: 5vh;
    left: 5vw;
  }

  /*LARGE*/
  @media screen and (min-width: 993px) {

    .autocompleteContainer {
      z-index: 1;
      /* width: 90vw;
      height: 50px;
      background-color: yellow; */
      position: absolute;
      top: 5vh;
      left: 5vw;
      display: block
    }
  
    .autocompleteInput {
      width: 40vw;
    }
  
    .autocompleteHr {
      display: none;
    }
    
    .autocompleteResult {
      width: 50vw;
      background-color: white;
      border: 2px, black, solid;
      padding-left: 7px;
    }

    .autocompleteButtonMobile {
      display: none
    }

    .map_container {
      width: calc(100vw - 230px);
      height: 100vh;
      position: relative;
    }

    .map_render {
      height: 100vh;
    }

    .map_render_full {
      width: calc(100vw - 230px);
    }

    .map_render_shrink {
      width: calc(100vw - 550px);
    }

    /*UNDERSLIDER*/
    .underslider_hide {
      display: none;
    }

    .underslider_show {
      display: none;
    }
  }

  /*SMALL*/
  @media screen and (max-width: 992px)  {

    .autocompleteContainer {
      display: none
    }
  
    .autocompleteInput {
      width: calc(100vw - 36px);
      font-size: larger;
      margin-bottom: 10px;
    }   
  
    .autocompleteHr {
      width: calc(100vw - 40px);
      display: block;
      margin-bottom: 20px;
    }
    
    .autocompleteResult {
      width: calc(100vw - 40px);
      background-color: white;
      border: 2px, black, solid;
      padding: 0 7px;
      margin-bottom: 10px;
      font-size: large;
    }

    .autocompleteButtonMobile {
      z-index: 1;
      width: 60px;
      height: 60px;
      border-radius: 15px;
      background-color: #574763;
      position: absolute;
      top: 5vw;
      left: 5vw;
      display: block;
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
    }

    .map_container {
      width: 100vw;
      height: 90vh;
      position: relative;
    }

    .map_render {
      height: 90vh;
      width: 100vw;
    }    

    .map_render_full, .map_render_shrink {
      width: 100vw;
    }

    /*UNDERSLIDER*/
    .underslider_hide {
      z-index: 100;
      position: absolute;
      bottom: calc(-25vh - 20px);
      left: 0;
      transition: 1s;
    }

    .underslider_show {
      z-index: 100;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: 1s;
    }
  }