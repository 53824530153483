.fieldset_style{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 250px;
    /* min-width: 300px; */
    border: 3px solid black;
    border-radius: 3px;
    margin-bottom: 5px;
}

.short_fieldset_style{
    width: 220px;
}

.modal_fieldset_style{
    width: 100%;
    height: 100%;
    border: 3px solid orange;
}

.modal_fieldset_INFO{
    max-height: 200px;
}

.modal_fieldset_ADMIN{
    max-height: 140px;
}

.modal_fieldset_APPO{
    max-height: 100%;
}

.legend_style{
    font-size: 20px;
    font-weight: bold;
    color: blanchedalmond;
    /* margin-left: 9px; */
}

.checkbox_style{
    height: 17px;
    width: 17px;
    margin-right: 10px;
    accent-color: #FBB040;
}

.label_style{
    /* text-decoration: underline; */
    /* border-bottom: 1px solid black; */
    /* border-bottom-left-radius: 4px; */
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2px;
    margin-right: 7px;
}
.label_style_BORDER_WHITE{
    /* text-decoration: underline; */
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    border-radius: 2px;
    margin-bottom: 2px;
    margin-right: 7px;
    text-align: center;
}

.input_style{
    font-size: 15px;
    width: 230px;
    height: 12px;
    padding: 5px;
    margin: 4px 10px;
    background: 
        linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to bottom, #FBB040, #f9db32) border-box;
    color: #313131;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-radius, border 0.5s;
}

.short_width_input{
    width: 180px;
}

.long_width_input{
    width: 560px;
    max-width: 560px;
}

.input_style:focus{
    outline: none;
    background: linear-gradient(to top, #FBB040, #f9db32) border-box;
    border-radius: 10px;
    border: 2px solid #444444;
}

.textarea_style{
    font-size: 15px;
    min-width: 230px;
    width: 230px;
    max-width: 560px;
    min-height: 20px;
    height: 30px;
    max-height: 50px;
    padding: 5px;
    margin: 4px 10px;
    background: 
        linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to bottom, #FBB040, #f9db32) border-box;
    color: #313131;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-radius, border 0.5s;
}

.long_textarea{
    width: 560px;
}

.textarea_style:focus{
    outline: none;
    background: linear-gradient(to top, #FBB040, #f9db32) border-box;
    border-radius: 10px;
    border: 2px solid #444444;
}

.select_style{
    font-size: 15px;
    width: 233px;
    height: 33px;
    padding: 5px;
    margin: 4px 10px;
    background: 
        linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to bottom, #FBB040, #f9db32) border-box;
    color: #313131;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-radius, border 0.5s;
}

.select_style:focus{
    outline: none;
    background: linear-gradient(to top, #FBB040, #f9db32) border-box;
    border-radius: 10px;
    border: 2px solid #444444;
}

.content_info{
    font-size: 16px;
    margin-left: 5px;
    overflow-wrap: break-word;
    width: 180px;
}

@media screen and (max-width: 992px) {
    .fieldset_style{
        width: calc(100% - 37px);
    }

    .modal_fieldset_style{
        width: 100%;
    }

    .modal_fieldset_ADMIN{
        max-height: 100%;
    }

    .legend_style{
        font-size: 18px;
    }

    .input_style{
        width: calc(100% - 37px);
    }

    .short_width_input{
        width: 160px;
        margin: 2px 8px;
    }

    .textarea_style{
        width: calc(100% - 37px);
        max-width: 300px;
        max-height: 40px;
    }

    .long_textarea{
        max-width: 560px;
    }

    .label_style{
        font-size: 18px;
    }

    .label_style_BORDER_WHITE{
        font-size: 16px;
    }
}

@media screen and (max-height: 440px) and (orientation: landscape) {
    .fieldset_style{
        width: 500px;
    }

    .input_style{
        height: 10px;
    }

    .textarea_style{
        max-height: 20px;
    }
}