@media screen and (min-width: 993px) {
    .topToolbar{
        width: 230px;
        height: 100vh;
        /* background-color: blueviolet; */
        background-image: linear-gradient(to right, #392546 , #574763);
        /* display: flex */
        min-width: 230px;
        position: relative;
        z-index: 11000;
    }

    /* .topToolbar-mobile{
        display: none
    } */    
    
    .topToolbar-mobile-hamburger {
        display: none
    }

    .logoBanner {
        width: 230px;
        min-width: 230px;
        margin-bottom: 35px;
    }

    .logoBanner img {
        width: 100%;
    }
}

.gradientText {
    padding-top: 10px;
    font-size: 22px;
    background: -webkit-linear-gradient(left, #FBB040, #F9EC32);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*MOBILE*/
@media screen and (max-width: 992px) {
    .topToolbar{
        width: 100vw;
        height: calc(10vh - 10px);
        min-height: 75px;
        background-image: linear-gradient(to right, #392546 , #574763);
        padding: 5px 15px;
        position: relative;
        display: flex;
        z-index: 11000;
    }

    /* .topToolbar-mobile{
        width: 100vw;
        height: calc(10vh - 10px);
        background-image: linear-gradient(to right, #392546 , #574763);
        padding: 5px 15px;
    } */

    .topToolbar-mobile-hamburger {
        width: calc(10vh - 10px);
        min-width: 75px;
        height: calc(10vh - 10px);  
        min-height: 75px;    
        background-repeat: no-repeat;
        background-size: cover;
    }

    .topToolbar-buttons-container {
        position: absolute;
        top: 10vh;
        z-index: 100;
        width: calc(100vh - 20px);
        background: linear-gradient(to right, #392546 , #574763);
        left: -10px;
        padding: 10px;
        border-top: 2px solid yellow;
    }

    .topToolbar-buttons-container-hide {
        display: none;
    }

    .logoBanner {}

    .logoBanner img {
        height: 100%;
    }
}