.hyperlinkText {
    color: blue;
    text-decoration: underline;
}

.markerList_container {

}

.markerRow_button {
    height: 22px;
    margin-left: 9px;
    margin-top: 4px;
}    

.markerRow_editButton,.markerRow_deleteButton,.markerRow_noteButton {
    width: 40px;
    height: 30px;
    margin-left: 15px;
    border-radius: 5px;
}

.markerRow_editButton {
    background-color: blueviolet;
}

.markerRow_deleteButton {
    background-color: red;
}

.markerRow_noteButton {
    background-color: #f9db32;
}

.markerRow_administrator {
    display: flex;
    background-color: yellow;
    padding: 15px;
    /* width: 100%; */
}
.markerRow_notesAdmin_container{
    border-bottom: #756d0a solid 2px;
    box-shadow: rgb(0 0 0 / 27%) 1px 6px 10px;
    /* z-index: 19999; */
    position: relative;
}

.markerRow_notes{
    display: flex;
    background-color: #d888d9;
    padding: 15px;
    /* width: 100%; */    
}

.markerRow_administrator_icon{
    height: 21px;
    width: 21px;
    min-width: 21px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: contain;
}

.markerRow_administrator_notes {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    width: 35%;
}

/* 
.markerRow_administrator_telephone {
    width: 15%;
}

.markerRow_administrator_email {
    width: 15%;
} */

.markerRow_administrator_text{
    margin-right: 25px;
    width: 15%;
}

.markerRow_container {
    display: flex;
    background-color: #F6E5FF;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    justify-content: space-between;
    position: relative;
    z-index: 5;
    box-shadow: rgb(0 0 0 / 27%) 1px 6px 10px;
}

.markerRow_markerIcon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 40px;
    max-width: 40px;
}

/*LABELS INFO ICON*/
.markerRow_info_icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 40px;
    max-width: 40px;
}
.markerRow_info_address_icon {
    background-image: url(../../public/icons/markerRow_address_icon.png);
}
.markerRow_info_appoTotal_icon {
    background-image: url(../../public/icons/markerRow_totalAppo_icon.png);
}
.markerRow_info_appoTodo_icon {
    background-image: url(../../public/icons/markerRow_notCompletedAppo_icon.png);
}
.markerRow_info_admin_icon {
    background-image: url(../../public/icons/admin_name.png);
}

.appointmentRow_info_appoDateTime_icon {
    background-image: url(../../public/icons/appoDateTime_icon.png);
}

.appointmentRow_info_appoType_icon {
    background-image: url(../../public/icons/appoType_icon.png);
}

.appointmentRow_info_appoState_icon {
    background-image: url(../../public/icons/appoState_icon.png);
}
/*END ------ LABELS INFO ICON*/

.markerRow_info {
    display: flex;
    align-items: center;
}

.markerRow_noteButton_noteContainer{
    position: relative;
    /* top: 25px;
    left: -15vw;
    background-color: #f9db32;
    border: solid 1px black;
    padding: 5px;
    width: 30vw; */
}

.markerRow_noteButton_noteContainer_text{
    position: absolute;
    top: 25px;
    left: -30vw;
    background-color: #f9db32;
    border: solid 1px black;
    padding: 5px;
    min-width: 60vw;
}

.markerRow_noteButton_noteContainer_arrow{
    position: absolute;    
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;    
    border-bottom: 20px solid black;
    top: 5px;
    left: 10px;
    /* border: solid 1px black; */
}

.markerRow_appointmentsList {
    display: flex;
    background-color: #dbb0f2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;

}

.markerRow_info_address {
    width: calc(30vw - 60px);
    padding-left: 15px;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden; */
}

.appointmentRow_info_container {
    display: flex;
    padding: 16px 8px;
    height: 30px;
    align-items: center;
    justify-content: space-between;
}

.appointmentRow_info_container_inner{
    display: flex;
} 

.appointmentRow_address {
    margin: 5px 0 20px 50px 
}

.appointmentRow_notes_container {
    background-color: #d888d9;
    padding: 5px 10px;
    margin: 0px 15px 15px;
    overflow-wrap: break-word;
}

/*LABELS*/

.markerRow_labels_button {
    width: 40px;
    height: 30px;
    margin-left: 15px;
    border-radius: 5px;
}

.tab_container {
    padding: 30px;
    box-shadow: rgb(0 0 0 / 27%) 0px 0px 10px 6px;
    background: darkcyan;
    border-radius: 10px;
    /* margin-top: 40px; */
    position: relative;
    z-index: 4;
}       

.tab_tab {
    width: 10vw;
    text-align: center;
    position: absolute;
    /* top: -70px; */
    background: darkcyan;
    height: 50px;
    padding-top: 10px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    box-shadow: rgb(0 0 0 / 27%) 0px 0px 10px 6px;
}

.tab_outerTab {
    overflow: hidden;
    padding: 20px 20px 0 20px;
    position: relative;
    height: 50px;
    width: 10vw;
}

.tabContainer_outer {
    position: relative;
    height: 70px;
    left: 0;
    display: flex;
}

.tabContainer_inner {
    position: absolute;
}

.italicText {
    font-style: italic;
}

/*APPOINTMENT LIST*/
.appointmentRow_icon {
    width: 30px;
    height: inherit;
    background-size: cover;
    background-repeat: no-repeat;
}

.appointmentRow_completed, .appointmentRow_todo {
    color: white;
    border-radius: 22px;
    width: 96px;
    padding: 0 10px;
}

.appointmentRow_todo {
    background-color: red;
}

.appointmentRow_completed {
    background-color: green;
}

.markerRow_modal_background_delete {
    z-index: 2000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);    
    padding-left: calc(50vw - 300px);
    padding-top: calc(50vh - 50px);
}

.markerRow_modal_background_edit {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);    
    padding-left: calc(50vw - 325px);
    padding-top: calc(50vh - 525px);
}

.markerRow_modal_container_edit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #392546;
    width: 600px;
    /* height: calc(100vh - 20px); */
    padding: 10px 25px;
    color: white;
}

.edit_appo {
    
}

.markerRow_modal_container_delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #392546;
    width: 600px;
    height: 100px;
    padding: 25px;
    color: white;
}

.markerRow_modal_text_address {
    width: 594px;
}

.markerRow_modal_text_note {
    width: 596px;
    max-width: 596px;
}

.markerRow_modal_text_input {
    width: 275px;
    /* margin-right: 50px; */
}

.markerRow_modal_title {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 10px;
    /* margin-top: 50px; */
    text-align: center;
}

.markerRow_modal_content_container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    /* height: 100%; */
    height: calc(100% - 50px);
}

.markerRow_modal_edit_appo {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 500px; */
}

.markerRow_modal_text_container {
    display: flex;
    justify-content: space-between;
}

.markerRow_modal_admin_info_container{
    width: 100%;
    height: 200px;
}

.markerRow_modal_button_container {
    display: flex;
    justify-content: space-evenly;
    margin: 2px;
    /* height: 50px; */
}

.markerRow_modal_button_confirm {
    border-radius: 6px;
    background-color: green;
    padding: 8px;
    color: white;
    width: 100px;
    text-align: center;
}

.markerRow_modal_button_cancel {
    border-radius: 6px;
    background-color: red;
    padding: 8px;
    color: white;
    width: 100px;
    text-align: center;
}

.checkbox_warning_container {
    display: flex;
    padding: 8px 0;;
}

.checkbox_warning {
    height: 17px;
    width: 17px;
    margin-right: 10px;
}

.markerRow_info_admin {
    width: calc(15vw - 60px);
    padding-left: 15px
}

.markerRow_info_appo {
    width: calc(10vw - 60px);
    padding-left: 15px;
}

.appointmentRow_info_name {
    width: 120px;
    min-width: 120px;
    padding-left: 15px;
}

.appointmentRow_info_dateTime_container {
    width: 100px;
    min-width: 100px;
    padding-left: 15px;
    text-align: center;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 8px;
    
}

.up {
    /* margin-top: 4px; */
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    }

.down {
    margin-bottom: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

@media screen and (min-width: 1601px) {
    .tabContainer {
        display: block;
        height: 100vh;
        width: calc(100vw - 260px);
        background-color: rgb(149, 137, 153);
        padding: 15px;
        overflow-y: scroll;
    }

    .tab_tabText {
        padding-top: 6px;
        font-size: 19px
    }

    .tab_tabIcon {
        display: none;
    }
    

    .markerRow_completed_label {
        width: 115px;
        min-width: 115px; 
        padding-left: 15px;
        height: 26px;
    }    

    .markerRow_completed_label_completed {        
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
        background-image: url(../../public/images/label_complete.png);
    }

    .markerRow_completed_label_notCompleted {
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;        
        background-image: url(../../public/images/label_notComplete.png);
    }

    .appointmentRow_buttons_container {
        display: flex
    }

    .appointmentRow_buttons_container_mobile {
        display: none
    }    

    .appointmentRow_button {
        height: 22px;
        margin-left: 9px;
        margin-top: 4px;
    }    

    .appointmentRow_editButton,.appointmentRow_deleteButton,.appointmentRow_noteButton,.markerRow_completeButton,.markerRow_decompleteButton {
        width: 40px;
        height: 30px;
        margin-left: 15px;
        border-radius: 5px;
    }

    .appointmentRow_editButton {
        background-color: blueviolet;
    }

    .appointmentRow_deleteButton {
        background-color: red;
    }

    .appointmentRow_noteButton {
        background-color: #f9db32;
    }

    .markerRow_completeButton {
        background-color: cadetblue;
    }

    .markerRow_decompleteButton {
        background-color: orangered;
    }

    .appointmentRow_completionDate{
        display: block;
    }

    .appointmentRow_completionDate_mobile{
        display: none;
    }
    
    .markerRow_info_icon {
        display: none;
    }

    .markerRow_info_text {
        display: block;
        cursor: pointer;
    }    
    .markerRow_info_text:hover {
        text-decoration: underline;
    }    

    .markerRow_info_buttons_container{    
        display: flex;
        align-items: center;
    }

    .markerRow_info_buttonsLabel_container {
        display: block;
    }
}

/*MOBILE*/
@media screen and (max-width: 992px) {
    .tabContainer {
        display: block;
        height: 90vh;
        width: 100vw;
        background-color: rgb(149, 137, 153);
        padding: 15px;
        overflow-y: scroll;
    }    

    .tab_tabText {
        display: none
    }

    .tab_tabIcon {        
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
    }

    .tab_tab {
        padding-top: 0;
    }
    

    .markerRow_completed_label {
        width: 35px;
        min-width: 35px; 
        /* padding-left: 15px; */
        height: 35px;
    }    

    .markerRow_completed_label_completed {        
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
        background-image: url(../../public/images/label_complete_mobile.png);
    }

    .markerRow_completed_label_notCompleted {
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;        
        background-image: url(../../public/images/label_notComplete_mobile.png);
    }

    .appointmentRow_buttons_container {
        display: none
    }

    .appointmentRow_buttons_container_mobile {
        display: flex;
        justify-content: center;
        margin: 5px 0 20px 0;
    }

    .appointmentRow_button {
        height: 30px;
        /* margin-left: 9px; */
        margin-top: 4px;
    }    

    .appointmentRow_editButton,.appointmentRow_deleteButton,.appointmentRow_noteButton,.markerRow_completeButton,.markerRow_decompleteButton {
        width: 60px;
        height: 40px;
        margin-left: 15px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
    }

    .appointmentRow_editButton {
        background-color: blueviolet;
    }

    .appointmentRow_deleteButton {
        background-color: red;
    }

    .appointmentRow_noteButton {
        background-color: #f9db32;
    }    

    .markerRow_completeButton {
        background-color: cadetblue;
    }

    .markerRow_decompleteButton {
        background-color: orangered;
    }

    .appointmentRow_completionDate{
        display: none;
    }

    .appointmentRow_completionDate_mobile{
        display: block;
    }
    
    .markerRow_info_icon {
        display: block;
    }

    .markerRow_info_text {
        display: none;
    }

    .markerRow_modal_text_container{
        flex-direction: column;
    }

    .markerRow_modal_text_input{
        font-size: 14px;
    }

    .markerRow_modal_container_edit{
        width: 100%;
        height: 580px;
    }

    .edit_appo{
        height: 320px;
    }

    .markerRow_info_buttons_container{    
        display: flex;
        align-items: center;
        margin-top: 15px;
        justify-content: space-around;
    }

    .markerRow_button {
        height: calc(100% - 10px);
        margin: 5px 0;
    }

    .markerRow_editButton,    
    .markerRow_deleteButton {
        width: 35vw;
        height: 40px;
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .markerRow_info_buttonsLabel_container {
        display: none;
    }

    .markerRow_container {
        display: block;
    }

    .markerRow_info_address {
        width: calc(80vw - 60px);
        padding-left: 15px;
    }

    .markerRow_info_admin {
        display: none;
    }

    .markerRow_info_appo {
        width: calc(10vw - 60px);
        padding-left: 0;
        min-width: 33px;
        display: flex;
        justify-content: center;
    }

    .markerRow_administrator {
        flex-direction: column;
    }

    .markerRow_administrator_text {
        width: auto;
        margin: 7px 0;
    }

    .markerRow_administrator_icon {
        margin: 7px 10px 7px 0;
    }
    
    .tabContainer {
        padding: 0;
    }

    .tab_container {
        padding: 30px 15px;
    }

    .appointmentRow_info_name {
        padding-left: 0;
    }

    .appointmentRow_info_dateTime_container {
        padding-left: 0;
    }

    .appointmentRow_info_container {
        padding: 16px 0;
    }
    
    .appointmentRow_info_container_inner{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .arrow {
        margin-top: 42px;
    }
    
    .up {
        margin-top: 44px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        }
    
    .down {
        margin-bottom: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

@media screen and (max-width: 1600px) and (min-width: 993px) {
    .tabContainer {
        display: block;
        height: 100vh;
        width: calc(100vw - 260px);
        background-color: rgb(149, 137, 153);
        padding: 15px;
        overflow-y: scroll;
    }

    .tab_tabText {
        display: none
    }

    .tab_tabIcon {        
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
        width: 100%;
        height: 100%;
    }

    .tab_tab {
        padding-top: 0;
    }
    

    .markerRow_completed_label {
        width: 115px;
        min-width: 115px; 
        padding-left: 15px;
        height: 26px;
    }    

    .markerRow_completed_label_completed {        
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
        background-image: url(../../public/images/label_complete.png);
    }

    .markerRow_completed_label_notCompleted {
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;        
        background-image: url(../../public/images/label_notComplete.png);
    }

    .appointmentRow_buttons_container {
        display: flex
    }

    .appointmentRow_buttons_container_mobile {
        display: none
    }

    .appointmentRow_button {
        height: 22px;
        margin-left: 9px;
        margin-top: 4px;
    }    

    .appointmentRow_editButton,.appointmentRow_deleteButton,.appointmentRow_noteButton,.markerRow_completeButton,.markerRow_decompleteButton {
        width: 40px;
        height: 30px;
        margin-left: 15px;
        border-radius: 5px;
    }

    .appointmentRow_editButton {
        background-color: blueviolet;
    }

    .appointmentRow_deleteButton {
        background-color: red;
    }

    .appointmentRow_noteButton {
        background-color: #f9db32;
    }

    .markerRow_completeButton {
        background-color: cadetblue;
    }

    .markerRow_decompleteButton {
        background-color: orangered;
    }

    .appointmentRow_completionDate{
        display: block;
    }

    .appointmentRow_completionDate_mobile{
        display: none;
    }

    .markerRow_info_icon {
        display: block;
    }

    .markerRow_info_text {
        display: none;
    }

    .markerRow_info_buttons_container{    
        display: flex;
        align-items: center;
    }

    .markerRow_info_buttonsLabel_container {
        display: block;
    }

    .arrow {
        margin-top: 42px;
    }
    
    .up {
        margin-top: 44px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        }
    
    .down {
        margin-bottom: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

@media screen and (max-height: 440px) and (orientation: landscape) {
    .markerRow_modal_container_edit{
        height: 280px;
    }

    .markerRow_modal_content_container{
        height: 220px;
        overflow-y: scroll;
    }

    .edit_appo{
        height: 280px;
    }

    .markerRow_modal_edit_appo{
        height: 180px;
        overflow-y: scroll;
    }
}